@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 0 0% 4%;

        --card: 0 0% 100%;
        --card-foreground: 0 0% 4%;

        --popover: 0 0% 100%;
        --popover-foreground: 0 0% 4%;

        --primary: 0 0% 9%;
        --primary-foreground: 30 17% 98%;

        --secondary: 40 13% 95%;
        --secondary-foreground: 0 0% 9%;

        --muted: 40 13% 95%;
        --muted-foreground: 0 0% 44%;

        --accent: 40 13% 95%;
        --accent-foreground: 0 0% 9%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 30 17% 98%;

        --border: 30 4% 89%;
        --input: 30 4% 89%;
        --ring: 0 0% 3.9%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 240 10% 3.9%;
        --foreground: 0 0% 98%;

        --card: 240 10% 3.9%;
        --card-foreground: 0 0% 98%;

        --popover: 240 10% 3.9%;
        --popover-foreground: 0 0% 98%;

        --primary: 0 0% 98%;
        --primary-foreground: 240 5.9% 10%;

        --secondary: 240 3.7% 15.9%;
        --secondary-foreground: 0 0% 98%;

        --muted: 240 3.7% 15.9%;
        --muted-foreground: 240 5% 64.9%;

        --accent: 240 3.7% 15.9%;
        --accent-foreground: 0 0% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 0% 98%;

        --border: 240 3.7% 15.9%;
        --input: 240 3.7% 15.9%;
        --ring: 240 4.9% 83.9%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }
}

.ProseMirror:focus {
    outline: none;
}
